@import '../../../../scss/theme-bootstrap';

.content-block-signup {
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  text-transform: none;
  &__wrapper {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
  }
  &__content-text {
    font-size: 1rem;
    line-height: 18px;
    width: 80%;
    padding: 10px 0;
    @include breakpoint($landscape-up) {
      width: initial;
      margin-#{$ldirection}: 0;
      padding: 0;
    }
    p {
      margin: 0;
    }
  }
  &__disclaimer {
    font-size: 12px;
  }
  &__messages {
    position: relative;
    text-align: center;
    margin: 5px 0;
    display: none;
    .content-block-signup--active-error &,
    .content-block-signup--active-success & {
      display: block;
    }
  }
  &__country-select-error,
  &__error {
    font-size: 12px;
    line-height: 1;
    color: $color-error;
    span {
      display: inline-block;
    }
  }
  &__success {
    font-size: 15px;
    line-height: 1;
    color: initial;
  }
  &__fields {
    @include pie-clearfix;
    .content-block-signup--active-success & {
      display: none;
    }
  }
  input[type='email'].content-block-signup__field {
    text-transform: none;
    color: $color-black;
    position: relative;
    border: none;
    background: transparent;
    padding: 0;
    width: 175px;
    font-size: 15px;
    line-height: 1.5;
    height: 20px;
    @include breakpoint($landscape-up) {
      width: 250px;
      line-height: 1;
    }
    &::placeholder {
      color: inherit;
    }
  }
  input.error[type='email'] {
    border: none !important;
  }
  &__submit {
    display: block;
    width: 100%;
    top: 0;
    background: transparent;
    padding: 0;
    color: $color-black;
    font-size: 12px;
    font-weight: bold;
    border: none;
    .content-block-signup--active-success & {
      display: none;
    }
    &:hover {
      color: inherit;
      background: inherit;
    }
  }
  &__button {
    padding: 5px;
    width: 100%;
    height: 20px;
  }
  &__form {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    border-bottom: 1px solid $color-black;
    margin: 5px;
    .content-block-signup--active-error & {
      border-color: $color-error;
    }
    .content-block-signup--active-success & {
      display: none;
    }
    &--email-error {
      input[type='email'].content-block-signup__field {
        color: $color-red;
      }
    }
  }
  &.content-block-signup--light {
    .content-block-signup__submit {
      color: $color-white;
    }
    input[type='email'].content-block-signup__field {
      color: $color-white;
    }
    .content-block-signup__form {
      border-color: $color-white;
    }
    &.content-block-signup--active-error {
      .content-block-signup__form {
        border-color: $color-error;
      }
    }
  }
  .content-block__line {
    margin-bottom: 6px;
    padding: 0;
  }
  p {
    padding: 0;
    margin: 0;
    line-height: 1;
  }
}
